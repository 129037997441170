<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo
        :height="`50px`"
        :full="true"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2
          v-if="title !== ''"
          class="mb-1"
        >
          {{ title }}
        </h2>
        <p
          v-if="message !== ''"
          class="mb-3"
        >
          {{ message }}
        </p>
        <b-img
          fluid
          :src="imgUrl"
        />
      </div>
    </div>
  </div>
<!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg,
} from 'bootstrap-vue'
import logo from '@core-custom/layouts/components/Logo.vue'
import store from '@/store/index'
import checkNested from 'check-nested'

export default {
  components: {
    BLink,
    BImg,
    logo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg')
        return this.downImg
      }

      if (this.img !== '') {
        return this.img
      }

      return this.downImg
    },
    title() {
      const configServer = store.getters['auth/getConfigServer']

      if (checkNested(configServer, 'manutencao.title')) {
        return configServer.manutencao.title || ''
      }

      return ''
    },
    message() {
      const configServer = store.getters['auth/getConfigServer']

      if (checkNested(configServer, 'manutencao.txt')) {
        return configServer.manutencao.txt || ''
      }

      return ''
    },
    img() {
      const configServer = store.getters['auth/getConfigServer']

      if (store.state.appConfig.layout.skin === 'dark') {
        if (checkNested(configServer, 'manutencao.img_dark')) {
          return configServer.manutencao.img_dark || ''
        }
      }

      if (checkNested(configServer, 'manutencao.img')) {
        return configServer.manutencao.img || ''
      }

      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
